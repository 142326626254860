import React from 'react'

import './Progress.css';

function Progress(props) {
  return (
    <div className="progress-outer">
      <div className="progress-inner" style={{width: props.progress+'%'}}>
        <span>{props.progress}%</span>
      </div>
    </div>
  )
}

export default Progress;
