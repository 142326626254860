import React from 'react';
import Typography from '@material-ui/core/Typography';

import Header from './Header';
import Footer from './Footer';

import './NotFound.css';

export default function functionName() {
  return (
    <div>
      <Header/>
      <Typography className="title" variant="h5" component="h2">Página não encontrada.</Typography>
      <Footer/>
    </div>
  )
}
