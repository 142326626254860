import React from 'react'
import { Button, Card, CardHeader, CardContent, CardActions, Icon, Typography, Slide } from '@material-ui/core';

import './Question.css';

function Question(props) {
  console.log(props.pergunta);
  return (
    <>
      <div className="card-header">
        <div className="row justify-content-center">
          <div className="col-6 col-md-4">
            <h6 className="text-secondary">Avaliado</h6>
            <h5>{props.pergunta?props.pergunta.avaliado.nome:''}</h5>
          </div>
          {/* <div className="col-6 col-md-4">
            <h6 className="text-secondary">Escolaridade</h6>
            <h5>{props.pergunta?props.pergunta.avaliado.escolaridade:''}</h5>
          </div> */}
          <div className="col-6 col-md-4">
            <h6 className="text-secondary">Setor</h6>
            <h5>{props.pergunta?props.pergunta.avaliado.setor:''}</h5>
          </div>

          <div className="col-6 col-md-3">
            <h6 className="text-secondary">Cargo</h6>
            <h5>{props.pergunta?props.pergunta.avaliado.cargo:''}</h5>
          </div>
          <div className="col-6 col-md-3">
            <h6 className="text-secondary">Data de admissão</h6>
            <h5>{props.pergunta?props.pergunta.avaliado.dataAdmissao:''}</h5>
          </div>

          {/* <div className="col-6 col-md-3">
            <h6 className="text-secondary">Tempo no Cargo</h6>
            <h5>{props.pergunta?props.pergunta.avaliado.dataAdmissaoCargo:''}</h5>
          </div> */}
          {/* <div className="col-6 col-md-3">
            <h6 className="text-secondary">Nº Loja</h6>
            <h5>{props.pergunta?props.pergunta.loja.split(' - ')[0]:''}</h5>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Question;
