import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AppIcon from '../assets/img/appicon.png';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MaskedInput from 'react-text-mask'


//icons
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//components
import Header from '../components/Header';
import Footer from '../components/Footer';

//css
import './Login.css';

//services
import api from '../services/api';


class Login extends Component {
  state = {
    cpf:'',
    senha:'',
    lembrar:false,
    user:{}
  }

  componentDidMount(){
    let accountInfo = localStorage.getItem('conta');
    let conta = JSON.parse(accountInfo);

    console.log(conta);

    if(conta){
      console.log('Iniciou com conta');
      this.setState({cpf:conta.username});
      this.setState({senha:conta.password});
      this.setState({lembrar:true});
    }
  }

  handleLogin = async (e) => {
    e.preventDefault();
    let accountInfo = {
      username: this.state.cpf+'',
      password: this.state.senha+'',
      type: 'CLB'
    }

    if(this.state.lembrar){
      console.log('com remember');
      localStorage.setItem('conta', JSON.stringify(accountInfo));
    }else{
      console.log('sem remember');
      localStorage.removeItem('conta');
    }

    const response = await api.post('auth',accountInfo);
    console.log(response);

    if(!response.data.status){
      alert(response.data.msg);
      return;
    }

    localStorage.setItem('user', JSON.stringify(response.data.user));
    if(!response.data.user.changePass){
      this.props.history.push('sigin');
      return;
    }

    this.props.history.push('home');
  }

  handleChange = e => {
    e.preventDefault();
    if(e.target.name == 'lembrar'){
      this.setState({ lembrar:!this.state.lembrar });
      // console.log(this.state);
      return
    }
    this.setState({ [e.target.name]:e.target.value });
  }

  render(){
    return (
      <div className="container-login">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="paper">
            <div className="avatar-box">
              <img src={AppIcon} className="avatar"/>
            </div>

            <form className="form" noValidate onSubmit={this.handleLogin}>
              <TextField
                className="input"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="Seu CPF"
                name="cpf"
                autoComplete="cpf"
                mask="999.999.999-99"
                autoFocus
                onChange={this.handleChange}
                value={this.state.cpf}
              />
              <TextField
                className="input"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="senha"
                label="Senha"
                type="password"
                id="senha"
                autoComplete="current-password"
                onChange={this.handleChange}
                value={this.state.senha}
              />
              <FormControlLabel
                control={<Checkbox checked={this.state.lembrar} color="primary" />}
                label="Lembrar de mim"
                name="lembrar"
                onChange={this.handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
              >
                Acessar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/forgot" variant="body2">
                    Esqueceu sua senha?
                  </Link>
                </Grid>
                <Grid item>

                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Footer />
          </Box>
        </Container>
      </div>
    );
  }
}

export default Login;
