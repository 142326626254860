import axios from 'axios';
import { getToken } from "./auth";

const api = axios.create();

const baseURL1 = 'https://one.applayos.com:5001/'
const baseURL2 = 'http://barbosa.ibrs.com.br:4032/';

const apis = {
  post:async (endpoint,filter)=>{
    filter.url = baseURL2+endpoint;
    console.log(filter.url);
    console.log(filter);
    return await api.post(baseURL1,filter);
  },
  put:async (endpoint,filter)=>{
    filter.url = baseURL2+endpoint;
    console.log(filter.url);
    console.log(filter);
    return await api.put(baseURL1,filter);
  }
}


export default apis;
