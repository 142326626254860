import React from 'react'
import { Slide, Card, CardContent, Typography } from '@material-ui/core';

import EmptyIcon from "../assets/img/emptyList.png";
import './Empty.css';

function Empty(props) {
  return (
    <Slide direction={props.animation} in={props.showEmpty} mountOnEnter unmountOnExit>
      <Card className="card">
        <CardContent className="card-body">
          <h2 className="text-center">Parabéns!</h2>
          <p className="text-secondary text-center">Você não possui nenhum questionário pendente.</p>
          <div className="empty-list-content">
            <div className="empty-list-content-img">
              <img src={EmptyIcon}/>
            </div>
          </div>
        </CardContent>
      </Card>
    </Slide>
  )
}

export default Empty;
