import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AppIcon from '../assets/img/appicon.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Header from '../components/Header';
import Footer from '../components/Footer';

//services
import api from '../services/api';

class Forgot extends Component {

  state = {
    cpf:'',
    status:false
  }

  componentDidMount(){

  }

  handleSend = async (e) => {
    if(this.state.status){return}
    this.setState({status:true});
    e.preventDefault();
    var data = {
      app_key:'515151515e1535',
      app_secret:'6sdf56sdf5656652',
      cpf:this.state.cpf
    }

    var endpoint = 'ws/api/avaliacao/enviaremail';
    const response = await api.post(endpoint,data);
    console.log(response);
    
    this.setState({status:false});
    alert(response.data.msg);

    if(response.data.status){
      this.props.history.push('/');
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]:e.target.value });
  }

  render(){
    return (
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="paper">
            <div className="avatar-box">
              <img src={AppIcon} className="avatar"/>
            </div>
            <br/>
            <br/>
            <Typography className="succes-text" variant="h5" component="h2">Recuperação de senha</Typography>
            <Typography className="succes-text" color="textSecondary" gutterBottom>Caso sua conta seja válida, você recebera uma senha provisória em seu e-mail.</Typography>

            <form className="form" noValidate onSubmit={this.handleSend}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="Seu CPF"
                name="cpf"
                autoComplete="cpf"
                autoFocus
                onChange={this.handleChange}
                value={this.state.cpf}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
              >
                Enviar
              </Button>
            </form>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Forgot;
