import React, { Component } from 'react';
import { Card, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, CardActions, CardContent, CardHeader, ButtonGroup, Button, Typography, TextField, Link, Box, Slide, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Backdrop, Fade, Icon } from '@material-ui/core';

//components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Progress from '../components/Progress';
import Empty from '../components/Empty';
import Question from '../components/Question';

//css
import './Home.css';
import './Cards.css';

//services
import api from '../services/api';

class Home extends Component {
  state = {
    pergunta:{avaliado:{nome:'',setor:'',loja:''},statusResp:'',statusPerg:''},
    perguntas:[],
    user:{nome:''},
    questionarios:[],
    questionario:{
      check:false,
      checkSend:false,
      plano:'',
      potencial:'',
      perguntas:[],
      avaliado:{
        nome:''
      }
    },
    values:{},
    progress:0,
    showCard:false,
    showConfirm:false,
    showModal:false,
    openConfirm:false,
    showEmpty:false,
    animation:'up',
    questIdx:0,
    mob:{}
  }

  async componentDidMount(){
    let user = JSON.parse(localStorage.getItem('user'));
    console.log(user)
    this.setState({ user:user });

    let time = (new Date().getTime())-1000*60*30;
    if(!user || user.lastlogin < time){
      localStorage.removeItem('user');
      this.props.history.push('/');
      return
    }

    var values = {}
    var endpoint = ['api2',user.session,'get','cadQuestionarios'].join('/');
    console.log('endpoint:',endpoint);

    const response = await api.post(endpoint,{ativo:true,status:'warning','avaliador.id':user._id+'', revisado:null});

    console.log('response:',response);

    //checkEmptyList
    if(response.data.length==0){
      this.setState({ animation : 'up',showEmpty : true });
      return
    }

    var isMobile = window.innerWidth <= 500
    var mob = !isMobile?{row:true}:{}

    this.setState({ mob:mob, animation:'left',showConfirm:true, questionarios:response.data,questionario:response.data[0] });

    var self = this;
    function updateSize() {
      var isMobile = window.innerWidth <= 500
      var mob = !isMobile?{row:true}:{}
      self.setState({ mob:mob });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
  }

  componentDidUpdate(){
    console.log('questionario: ',this.state.questionario);
    console.log('questionarios: ',this.state.questionarios);
    console.log('showConfirm: ',this.state.showConfirm);
  }

  changeResp = (e) => {
    console.log(e.target.value);
    var [value,pergIdx] = e.target.value.split('#');

    // value = parseInt(value)
    pergIdx = parseInt(pergIdx)
    console.log(value);
    console.log(pergIdx);

    let mask = {
      '2.5':'Inadequado',
      '5.0':'Em Desenvolvimento',
      '7.5':'Adequado',
      '10.0':'Muito Bom',
    }

    let questionario = this.state.questionarios.find(e=>e._id+'' == this.state.questionario._id+'');
    if(['2.5','5.0'].indexOf(value)>=0){
      questionario.perguntas[pergIdx].justificativa = '';
    }

    questionario.perguntas[pergIdx].respValue = parseFloat(value).toFixed(1);
    questionario.perguntas[pergIdx].respText = mask[value];
    questionario.perguntas[pergIdx].status = 'success';
    if(!questionario.perguntas.find(e=>e.respValue==0)){
      if(!questionario.perguntas.find(e=>['2.5','5.0'].indexOf(e.respValue)>=0 && e.justificativa=='')){
        if(questionario.avaliado.id == questionario.avaliador.id){
          questionario.checkSend = true;
        }
        questionario.check = true;
      }else{
        questionario.check = false;
        questionario.checkSend = false;
      }
    }else{
      questionario.check = false;
      questionario.checkSend = false;
    }
    this.setState({ questionario:questionario, questionarios: this.state.questionarios });
  }

  calcProgres = () => {
    return ((100*this.state.questionarios.filter(e=>e.status=='success').length/this.state.questionarios.length).toFixed(2));
  }

  previous = () => {
    let idx = this.state.questionarios.findIndex(e=>e._id+'' == this.state.questionario._id+'');
    if(idx==0){return}
    this.setState({ animation:'left', showConfirm:false, questionario:this.state.questionarios[idx-1], questIdx:idx-1 });

    setTimeout(()=>{
      this.setState({ animation:'right', showConfirm:true });
    },250);
  }




  next = () => {
    let idx = this.state.questionarios.findIndex(e=>e._id+'' == this.state.questionario._id+'');
    if(idx==this.state.questionarios.length-1){return}

    this.setState({ animation:'right', showConfirm:false, questionario:this.state.questionarios[idx+1], questIdx:idx+1 });

    console.log('next: ',this.state.questionarios[idx+1]);

    setTimeout(()=>{
      this.setState({ animation:'left', showConfirm:true });
    },250);
  }

  handleClose = () => {
    this.setState({showModal:false,openConfirm:false})
  }

  handleCancel = () => {
    this.setState({ animation:'right', showConfirm:false });

    let perguntas = this.genPergs();
    let progress = this.calcProgres(perguntas);
    let pergunta = perguntas[this.state.pergIdx]

    this.setState({ perguntas:perguntas, pergunta:pergunta, progress:progress });

    setTimeout(()=>{
      this.setState({ animation:'left', showCard:true });
    },300);
  }

  handleConfirm = async () => {
    this.setState({openConfirm:false});

    let quest = this.state.questionario;

    quest.status = 'success';
    quest.revisado = true;

    var endpoint = ['api2',this.state.user.session,'operacs','cadQuestionarios'].join('/');
    const response = await api.put(endpoint,quest);
    console.log(response);

    let progress = this.calcProgres();
    this.setState({ progress:progress });

    console.log('handleConfirm: ',this.state.questionario);

    //checkEmptyList
    if(!this.state.questionarios.find(e=>!e.revisado)){
      this.setState({ animation:'up', showEmpty:true, showConfirm:false });
      return;
    }

    this.next();
  }

  handleClean = () => {
    let quest = this.state.questionario;
    quest.check = false;
    for(let pergs of quest.perguntas){
      pergs.respText = '';
      pergs.respValue = 0;
    }

    this.setState({ questionario:quest });
  }

  handleChange = (e) => {
    let quest = this.state.questionarios.find(f=>f._id+'' == this.state.questionario._id+'');
    quest.perguntas[e.target.name.split('#')[1]].justificativa = e.target.value;
    if(!quest.perguntas.find(e=>e.respValue==0)){
      if(!quest.perguntas.find(e=>['2.5','5.0'].indexOf(e.respValue)>=0 && e.justificativa=='')){
        if(quest.avaliado.id == quest.avaliador.id){
          quest.checkSend = true;
        }
        quest.check = true;
      }else{
        quest.check = false;
        quest.checkSend = false;
      }
    }else{
      quest.check = false;
      quest.checkSend = false;
    }
    this.setState({ questionario:quest, questionarios:this.state.questionarios });
  }

  handleChangeInput = (e) => {
    let quest = this.state.questionarios.find(f=>f._id+'' == this.state.questionario._id+'');
    quest[e.target.name] = e.target.value;

    if(quest.potencial && quest.plano && quest.potencial!='' && quest.plano!=''){
      quest.checkSend = true;
    }

    this.setState({ questionario:quest, questionarios:this.state.questionarios });
  }

  handlePotencial = (e) => {
    let quest = this.state.questionarios.find(e=>e._id+'' == this.state.questionario._id+'');
    quest.potencial = e.target.value;

    if(quest.potencial && quest.plano && quest.potencial!='' && quest.plano!=''){
      quest.checkSend = true;
    }

    this.setState({ questionario:quest, questionarios:this.state.questionarios });
  }

  render(){
    return (
      <div>
        <Header />
        <div className="mycard">
          <div className="mycontent">

            <Empty animation={this.state.animation} showEmpty={this.state.showEmpty}/>

            <Slide direction={this.state.animation} in={this.state.showConfirm} mountOnEnter unmountOnExit>
              <div className="card">
                <Question pergunta={this.state.questionario}/>

                <div className="card-body">
                  {this.state.questionario.perguntas.map((e,i)=>(
                    <div className="card-body-confirm" key={i}>
                      <div className="card-body-item">
                        <div className="card-body-item-status">
                          {this.state.questionario.perguntas[i].respText!=""?<Icon>check_circle_outline</Icon>:null}
                        </div>
                        <h6 className="text-secondary tic">Competência:</h6>
                        <span className="card-body-pergunta tac">{e.competencia}</span>
                      </div>

                      <div className="card-body-item">
                        <h6 className="text-secondary tic">Pergunta {i+1}:</h6>
                        <span className="card-body-pergunta tac">{e.pergunta}</span>
                      </div>

                      <FormControl className="mt-4" component="fieldset" onChange={this.changeResp}>
                        <RadioGroup aria-label="gender" name="gender1" {...this.state.mob}>
                          <FormControlLabel value={ '2.5'+'#'+i } control={<Radio checked={this.state.questionario.perguntas[i].respText === 'Inadequado'} color="primary"/>} label="Inadequado" />
                          <FormControlLabel value={ '5.0'+'#'+i } control={<Radio checked={this.state.questionario.perguntas[i].respText === 'Em Desenvolvimento'} color="primary"/>} label="Em Desenvolvimento" />
                          <FormControlLabel value={ '7.5'+'#'+i } control={<Radio checked={this.state.questionario.perguntas[i].respText === 'Adequado'} color="primary"/>} label="Adequado" />
                          <FormControlLabel value={ '10.0'+'#'+i } control={<Radio checked={this.state.questionario.perguntas[i].respText === 'Muito Bom'} color="primary"/>} label="Muito Bom" />
                        </RadioGroup>
                      </FormControl>

                      {/* {['2.5','5.0'].indexOf(e.respValue)>=0? */}
                        <div className="p-1 mt-4">
                          <form className="form" noValidate onSubmit={this.handleLogin}>
                            <h6 className="text-secondary">Justifique sua resposta</h6>
                            <TextField
                              className="w-100"
                              aria-label=""
                              rows="3"
                              multiline
                              variant="outlined"
                              name={"justificativa#"+i}
                              placeholder=""
                              onChange={this.handleChange}
                              value={this.state.questionario.perguntas[i].justificativa}
                            />
                          </form>
                        </div>
                        {/* :null} */}
                    </div>
                  ))}
                </div>

                {/* {!this.state.questionario.check || this.state.questionario.avaliado.id+'' == this.state.questionario.avaliador.id+''?null: */}
                  <div className="card-body card-plan pt-0">
                    <div className="card-body-item">
                      <h4 className="text-secondary tic">Plano de Ação:</h4>
                      <span className="card-body-pergunta tac">Baseado em suas respostas, qual sua indicação para este colaborador.</span>
                    </div>
                    <form className="form" noValidate>
                      <TextField
                        className="w-100"
                        aria-label=""
                        rows="3"
                        multiline
                        variant="outlined"
                        name={"plano"}
                        placeholder=""
                        onChange={this.handleChangeInput}
                        value={this.state.questionario.plano}
                      />
                    </form>
                    <FormControl component="fieldset" value={this.state.questionario.potencial} onChange={this.handlePotencial}>
                      <div className="card-body-item mt-5">
                        <h4 className="text-secondary tic">Potencial de Carreira:</h4>
                      </div>

                      <RadioGroup className="mt-4" aria-label="gender" name="gender1">
                        <FormControlLabel value="Substituição" control={<Radio color="primary" checked={this.state.questionario.potencial === "Substituição"}/>} label="Substituição" />
                        <FormControlLabel value="Desenvolvimento na função" control={<Radio color="primary" checked={this.state.questionario.potencial === "Desenvolvimento na função"}/>} label="Desenvolvimento na função" />
                        <FormControlLabel value="Mudança de loja" control={<Radio color="primary" checked={this.state.questionario.potencial === "Mudança de loja"}/>} label="Mudança de loja" />
                        <FormControlLabel value="Mudança de setor" control={<Radio color="primary" checked={this.state.questionario.potencial === "Mudança de setor"}/>} label="Mudança de setor" />
                        <FormControlLabel value="Mudança de função" control={<Radio color="primary" checked={this.state.questionario.potencial === "Mudança de função"}/>} label="Mudança de função" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                

                <div className="card-footer footer2">
                  <Button variant="contained" className="button" color="primary" size="small" onClick={()=>{this.setState({openConfirm:true})}} disabled={!this.state.questionario.checkSend}>Enviar</Button>
                </div>
              </div>
            </Slide>

          </div>
        </div>
        {this.state.showConfirm?(
          <div className="nav-buttons">
            <Button onClick={this.previous} variant="contained" size="small" disabled={this.state.questIdx==0}><Icon>arrow_back</Icon> Anterior</Button>
            <div className="nav-label text-secondary">{(this.state.questIdx+1)+'/'+this.state.questionarios.length}</div>
            <Button onClick={this.next} variant="contained" size="small" disabled={this.state.questIdx==this.state.questionarios.length-1}>Próxima <Icon>arrow_forward</Icon></Button>
          </div>
        ):null}

        <Dialog open={this.state.openConfirm} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Realmente deseja enviar as respostas?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Caso envie não será possível alterar suas respostas.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Não
            </Button>
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>

        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className="modal-home" open={this.state.showModal} onClose={this.handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
          <Fade in={this.state.showModal}>
            <div className="paper-home">
              <Typography variant="h4" component="h4">Avaliação Barbosa</Typography>
              <Typography id="transition-modal-description">Todos os dados dos avaliadores e respostas são confidênciais e codificados com uma criptografia de alta segurança.</Typography>
            </div>
          </Fade>
        </Modal>

        <Footer />

      </div>
    );
  }
}

export default Home;
