import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AppIcon from '../assets/img/appicon.png';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


import Header from '../components/Header';
import Footer from '../components/Footer';

//services
import api from '../services/api';

class SigIn extends Component {
  state = {
    cpf:'',
    senha:'',
    resenha:'',
    user:{}
  }

  componentDidMount(){
    let storage = localStorage.getItem('user');
    let user = JSON.parse(storage);

    if(!user){
      this.props.history.push('/');
      return
    }

    this.setState({cpf:user.cpf});
    this.setState({user:user});
  }

  handleSend = async (e) => {
    e.preventDefault();
    console.log(this.state);

    if(this.state.senha != this.state.resenha){
      alert("As senhas não coincidem.");return
    }

    if(this.state.senha.length<4){
      alert("Sua senha deve conter no mínimo 4 caracteres.");return
    }

    let user = this.state.user;
    user.password = this.state.senha;
    user.changePass = true;

    var endpoint = ['api2',this.state.user.session,'operacs','cadColaboradores'].join('/');
    const response = await api.put(endpoint,user);
    console.log(response);

    alert('Senha alterada com sucesso');
    this.props.history.push('/');
  }

  handleChange = e => {
    this.setState({ [e.target.name]:e.target.value });
  }

  render(){
    return (
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="paper">
            <div className="avatar-box">
              <img src={AppIcon} className="avatar"/>
            </div>

            <br/>
            <br/>
            <Typography className="succes-text" variant="h5" component="h2">Alteração de senha</Typography>
            <Typography className="succes-text" color="textSecondary" gutterBottom>Preencha os campos abaixo para alterar sua senha, ela deve conter no mínimo 4 caracteres.</Typography>


            <form className="form" noValidate onSubmit={this.handleSend}>
              <TextField
                disabled
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cpf"
                label="Seu CPF"
                name="cpf"
                autoComplete="cpf"
                autoFocus
                onChange={this.handleChange}
                value={this.state.cpf}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="senha"
                label="Nova Senha"
                type="password"
                id="senha"
                autoComplete="current-password"
                onChange={this.handleChange}
                value={this.state.senha}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="resenha"
                label="Confirmar Senha"
                type="password"
                id="resenha"
                autoComplete="current-password"
                onChange={this.handleChange}
                value={this.state.resenha}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
              >
                Enviar
              </Button>
            </form>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }

}

export default SigIn;
