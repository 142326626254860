import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Header.css'
import barbosa from '../assets/img/logo-barbosa.png';
import Icon from '@material-ui/core/Icon';


class Header extends Component {

  handleLogout = ()=>{
    localStorage.removeItem('user');
  }

  toggleMenu = ()=>{
    var menu = document.querySelector('.dropdown-menu')
    if(!menu.style.display || menu.style.display == '' || menu.style.display == 'none'){
      document.querySelector('.dropdown-menu').style.display = 'block';
    }else{
      document.querySelector('.dropdown-menu').style.display = 'none';
    }
  }

  componentDidUpdate(){
  }

  render (){
    return (
      <header id="main-header">
        <div className="header-content">
          <Link to="/home">
            <img src={ barbosa } alt="Barbosa Supermercados"/>
          </Link>
          <div className="btn-group">
            <button type="button" className="btn text-white" onClick={()=>{this.toggleMenu()}}><Icon className="my-icon">menu</Icon></button>
            <div className="dropdown-menu">
              <Link to="/home" className="dropdown-item">Home</Link>
              <Link to="/avaliacoes" className="dropdown-item">Minhas Avaliações</Link>
              <div className="dropdown-divider"></div>
              <Link to="/" onClick={()=>{this.handleLogout()}} className="dropdown-item">Sair</Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
