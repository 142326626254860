import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './pages/Login';
import Home from './pages/Home';
import Avaliacoes from './pages/Avaliacoes';
import SigIn from './pages/SigIn';
import Forgot from './pages/Forgot';

import NotFound from './components/NotFound';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/home" exact component={Home} />
      <Route path="/avaliacoes" exact component={Avaliacoes} />
      <Route path="/sigin/" component={SigIn} />
      <Route path="/forgot/" component={Forgot} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes;
